<template>
    <div class="d-flex flex-column align-center">
        <product-banner>
            Hire Our Content Team To Produce All The Content For You
        </product-banner>
        <product-card id="470" style="text-align: left !important">
            <template #title>1 Authoring Credit</template>
            <template #description>
                Let our professional authoring team create your Amp.
                <br />
                <strong>Our Done-For-You authored Amps include:</strong>
                <ul class="colored">
                    <li>
                        Optimising your headlines, keywords and content for more
                        exposure using our proprietary Hook Mixing strategy
                    </li>
                    <li>
                        Following the varying rules of the various sites we
                        publish to to ensure maximum approval rates
                    </li>
                    <li>
                        Creating and optimising the different formats for your
                        content including a video, article, infographic,
                        slideshow, audio ad and blog post.
                    </li>
                </ul>

                You can leave instructions and feedback for our writers. The
                more information you provide the better.
            </template>
        </product-card>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { ProductBanner, ProductCard } from '@/components/ProductCard';
@Component({
    components: {
        ProductBanner,
        ProductCard
    }
})
export default class DoneForYou extends Vue {
    mounted() {
        this.$emit(
            'appointment',
            'https://try.ampifire.com/meetings/jbulzak/amp-strategy-meeting'
        );
    }
}
</script>

<style lang="scss">
.colored {
    text-align: left;
    li::marker {
        color: $primary-color;
    }
}
</style>
